// Fonts to Import

@font-face {
  font-family: CabifyCircularWeb-Bold;
  src: url(./../../../assets/fonts/CabifyCircularWeb-Bold.otf) format("opentype");
}

@font-face {
  font-family: CabifyCircularWeb-Book;
  src: url(./../../../assets/fonts/CabifyCircularWeb-Book.otf) format("opentype");
}

@font-face {
  font-family: CabifyCircularWeb-Light;
  src: url(./../../../assets/fonts/CabifyCircularWeb-Light.otf) format("opentype");
}

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";
@import "@nebular/auth/styles/globals";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "~primeicons/primeicons.css";


$gutters: (
  xs: 1rem,
  sm: 1.5rem,
  md: 2rem,
  lg: 2.5rem,
  xl: 3rem,
  xxl: 3.5rem
);



@import "bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";


// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

.fileImageForng2-table {
  width: 100px;
}

.fileImageForForm {
  border-radius: 50%;
  margin-top: 25px;
  margin-left: 18px;
  height: 140px;
  width: 140px;
}

.star {
  color: red;
}


nb-card {
  overflow-y: scroll;
  overflow: visible;
}

.nb-card-height-change {
  max-height: 950px;
}

agm-map {
  height: 500px;
}

.header-menu-button {
  width: 165px;
}

.ng-select-container {
  height: 40px !important;
  background-color: var(--input-basic-background-color) !important;
  border-color: var(--input-basic-border-color) !important;
  color: var(--layout-text-color) !important;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input{
  color: var(--layout-text-color) !important;
}

select {
  height: 40px !important;
  background-color: var(--input-basic-background-color) !important;
  border-color: var(--input-basic-border-color) !important;
  color: var(--layout-text-color) !important;
}

.ng-select-container:hover {
   background-color: var(--layout-background-color)
}

[nbInput].status-basic:hover {
  background-color: var(--layout-background-color)!important;
}

// ::ng-deep input:-internal-autofill-selected {
//   background-color: var(--input-basic-background-color) !important; /* Light mode */
// }

::ng-deep input:-webkit-autofill {
  background-color: var(--input-basic-background-color) !important;
}

// ::ng-deep input:is(:-webkit-autofill, :autofill) {
//   border: 3px dotted orange;
// }

// .input:-webkit-autofill {transition: background-color 5000s ease-in-out 0s;}

.ng-select {
  font-weight: bold;
}

.dialogBoxTitle {
  font-weight: 600;
  line-height: 3;
}

.alert-danger {
  color: red;
  background-color: unset;
  border-color: unset;
}

.changePasswordDialogBox {
  width: 50vw;
}

.documentUpdateDialogBox {
  width: 30vw;
}

.documentExpiryDetailsDialogBox {
  width: 30vw;
}

nb-card-body label {
  font-size: 14px !important;
  color: var(--layout-text-color)
}

.menubutton {
  text-align-last: right;
}

.promocode-input {
  max-width: unset !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
  height: 23px;
  width: 23px;
  background-image: url('../../../assets/images/arrow-down.svg');
  background-repeat: no-repeat;
  margin-right: 8px;
  transition-duration: .15s;
  transition-property: transform;
  transition-timing-function: ease-in;
}

.ng-select-opened .ng-arrow-wrapper {
  transform: rotate(180deg);
  transition-duration: .15s;
  transition-property: transform;
  transition-timing-function: ease-in;
  background-image: url('../../../assets/images/arrow-down.svg');
  background-repeat: no-repeat;

}

.ng-select .ng-clear-wrapper {
  margin-top: 6px;
}

.nbtabset_side_title {
  font-size: 15px;
}

a.tab-link {
  align-items: baseline;
}

nb-accordion {
  box-shadow: none;
}

nb-accordion-item-header {
  background-color: var(--layout-background-color)!important;
  color: var(--layout-text-color);
}

img.fileImageForng2-table {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 2px;
}

img.fileImageForng2-table.pending {
  border: 3px solid #ffaa00;
}

img.fileImageForng2-table.active {
  border: 3px solid #00d68f;
}

img.fileImageForng2-table.inactive {
  border: 3px solid #ff3d71;
}

.warning-color {
  color: #ffaa00;
}

.success-color {
  color: #00d68f;
}

.danger-color {
  color: #ff3d71;
}

.primary-color {
  color: #000;
}



.passwordHide {
  display: none;
}

.inner-addon {
  position: relative;
}

.inner-addon .far,
.inner-addon .fas {
  position: absolute;
  padding: 2% 2%;
  z-index: 100;
  cursor: pointer;
  right: 3px;
}

.hideshowpwd {
  position: relative;
}

.hideshowpwd .far,
.hideshowpwd .fas {
  position: absolute;
  padding: 2% 2%;
  z-index: 100;
  cursor: pointer;
  right: 8px;
  bottom: 0;
}


.table-edit-icon {
  font-size: 14px;
  color: var(--background-color);
}

.table-email-icon {
  font-size: 25px;
  color: var(--menu-submenu-item-active-hover-text-color);
}

.buttonUnClickable {
  pointer-events: none;
}

.margin-top {
  margin-top: -20px;
}

.card-button {
  width: 100%;
}

.text-end {
  text-align: end;
}

nb-select.appearance-outline.status-basic .select-button {
  background-color: unset;
}

.input-group-append .btn {
  border: unset;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0 0.25rem 0.25rem 0;
}


nb-card-body {
  overflow: visible;
}

.ng-dropdown-panel {
  z-index: 1 !important;
}

.card-headers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.layout {
  min-width: unset !important;
}

.row-form {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-weight: 100;
  color: #8f9bb3;
}

// [nbInput].status-basic:hover {
//   background-color: #eee;
// }

::ng-deep nb-radio.status-basic .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
}

:host ::ng-deep nb-select.appearance-outline.status-basic .select-button:focus:hover {
  background-color: unset !important;
  border-color: unset !important;
}

::ng-deep nb-select.appearance-outline.size-medium .select-button {
  line-height: inherit ;
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: var(--layout-text-font-size);
  background-color: var(--input-basic-background-color) !important;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  padding: 0.375em 2.2rem 0.375em .75em !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--layout-text-color) !important;
}

::ng-deep nb-option {
   color: var(--layout-text-color)
}
::ng-deep nb-option:hover {
  color: var(--layout-text-color)
}

.form-control {
  font-size: unset;
  background-color: var(--input-basic-background-color);
  color: var(--layout-text-color) !important;
}
/* Assuming the input has a class 'form-control' */
.form-control::placeholder {
  color: var(--layout-text-color) !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.label {
  color:  var(--link-text-color);

}

[nbInput].status-basic {
  color:  var(--link-text-color);
}

.table > :not(caption) > * > * {
  color:  var(--link-text-color);
}
.error-snackbar .mat-mdc-snackbar-surface{
  background-color: #ff3d71 !important;
}
.mat-mdc-button .mat-mdc-button-persistent-ripple::before{
  background-color: transparent !important;
  // background-color: var(--layout-background-color) !important;
}
.success-snackbar  .mat-mdc-snackbar-surface{
  background-color: #00d68f !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.toastr-overlay-container{
  justify-content: center !important;
  align-items: flex-end !important;
}
nb-toast.status-success .title,
nb-toast.status-danger .title{
  display: none !important;
}
nb-toast.status-success,
nb-toast.status-danger,
nb-toast.status-success.destroy-by-click:hover,
nb-toast.status-danger.destroy-by-click:hover{
  background-color:  #333 !important;
  border-color: #333 !important;
}
nb-toast.status-success .icon-container,
nb-toast.status-danger .icon-container{
  background: none !important;
  color: #fff;
}
